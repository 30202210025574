import { Injectable } from '@angular/core';
import * as moment from 'moment';

interface infopais {
  pais: string;
  code: string;
}
@Injectable({
  providedIn: 'root',
})
export class ComunService {
  private retinapaises: string[] = [
    'Colombia',
    'Ecuador',
    'Uruguay',
    'Bolivia',
    'Peru',
    'Mexico',
  ];
  private listapaises: infopais[] = [
    { pais: 'Colombia', code: 'co' },
    { pais: 'Ecuador', code: 'ec' },
    { pais: 'Bolivia', code: 'bo' },
    { pais: 'Perú', code: 'pe' },
    { pais: 'México', code: 'mx' },
    { pais: 'Uruguay', code: 'uy' },
  ];

  public coloresYears: string[] = [
    '#ABEBC6',
    '#8b0d90',
    '#cd0078',
    '#f53158',
    '#ff6d34',
    '#ffa600',
    '#64E89E',
    '#5DADE2',
  ];

  constructor() {}

  coloresPaises(paises: string[]) {
    //console.log ('pays', paises)
    let colores = [
      { pais: 'Colombia', color: '#FFCD00' },
      { pais: 'Peru', color: '#C8102E' },
      { pais: 'Uruguay', color: '#001489' },
      { pais: 'Bolivia', color: '#007A33' },
      { pais: 'Mexico', color: '#006341' },
      { pais: 'Ecuador', color: '#FFD100' },
    ];
    const salida = [];
    for (const pais of paises) {
      const color = colores.find((col) => col.pais === pais).color;
      salida.push(color);
      //console.log ("col",color)
    }
    return salida;
  }
  coloresPaisesAlpha(paises: string[]) {
    //console.log ('pays', paises)
    let colores = [
      { pais: 'Colombia', color: '#ffcc0080' },
      { pais: 'Peru', color: '#c8102f80' },
      { pais: 'Uruguay', color: '#00158980' },
      { pais: 'Bolivia', color: '#007a3380' },
      { pais: 'Mexico', color: '#00634080' },
      { pais: 'Ecuador', color: '#ffd00080' },
    ];
    const salida = [];
    for (const pais of paises) {
      const color = colores.find((col) => col.pais === pais).color;
      salida.push(color);
      //console.log ("col",color)
    }
    return salida;
  }

  getPaisesRetina(): string[] {
    return this.retinapaises;
  }
  getpaises(): infopais[] {
    return this.listapaises;
  }
  mesYearFromDate(date: Date): [string, number] {
    const mes = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return [mes, year];
  }

  mesYearFromDateNumber(date: Date): [number, number, number, number] {
    const anterior = moment(date).subtract(1, 'months').toDate();

    let mesanterior = ('0' + (anterior.getMonth() + 1)).slice(-2);
    const yearanterior = anterior.getFullYear();
    const mes = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    if (mesanterior === '02' && yearanterior === 2016) {
      mesanterior = '03';
    }
    return [+mes, year, +mesanterior, yearanterior];
  }

  existeInfoLastYear(date: Date): boolean {
    const mes = date.getMonth() + 1;
    const year = date.getFullYear();
    if (year === 2016 || (year === 2017 && mes < 3)) {
      //console.log ( 'MATANGA`S ANTERIOR', mes, year )
      return false;
    }

    //console.log ( 'YES YEAR ANTERIOR', mes, year )

    return true;
  }
}
