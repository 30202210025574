import { PersonajesI } from './../models/persona.model';
import { map, tap, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  public URL = 'https://retinalatina.org/';
  //public URL = 'https://retinadevel.wpengine.com/';
  public API = `${this.URL}wp-json/ra/v1/`;

  constructor(private http: HttpClient) {}

  getPersonas(): Observable<PersonajesI[]> {
    return this.http.get(`${this.API}lista_talento`).pipe(
      tap((res) => console.log(res[1054])),
      /* map((res: PersonajesI[]) => {
        res.filter((res) => res.imagen);
      }), */
      map((res: PersonajesI[]) => {
        return res;
        //return res.filter((res) => res.imagen);
      }),
      tap((res) => console.log(res))
    );
  }
  getPersona(id: number): Observable<PersonajesI> {
    return this.http.get(`${this.API}lista_talento/${id}`).pipe(
      map((res: PersonajesI) => {
        return res;
      })
      //tap((res) => console.log(res))
    );
  }
}
